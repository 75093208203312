"use client";

import { IBM_Plex_Mono } from "next/font/google";

import "./global.css";

const ibmPlexMono = IBM_Plex_Mono({
	weight: "400",
	subsets: ["latin"],
	variable: "--code-font",
	display: "swap",
	preload: true,
});

export default function Index() {
	return <div>Coming soon</div>;
}
